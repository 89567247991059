import { useContext, useEffect, useRef, useState } from "react";

import TypingTextV2 from "../../components/TypingTextV2";
import StyledContentPanel from "../../components/StyledContentPanel";
import { animated, useSpring } from "react-spring";
import { TopicV2 } from "@kazvabg/voterapp-model";
import Hls from 'hls.js';
import StyledButton from "../../components/StyledButton";
import { AppContext } from "../../App";

const Component: React.FC<{ onComplete: () => void, topic: TopicV2 }> = (props) => {

    const { cookieConsent } = useContext(AppContext);
    const animationsStartedRef = useRef<boolean>(false);
    
    useEffect(() => {
        if(cookieConsent && !animationsStartedRef.current) {
            animationsStartedRef.current = true;
            startAnimations();
        }
    }, [cookieConsent]);

    const startAnimations = () => {
        setState({...state, currentMessageFadeIn: 0 });
    };

    const [state, setState] = useState({
        currentMessageFadeIn: -1,
        currentMessageText: -1
    });

    const [titlePanelStyle, titlePanelApi] = useSpring(() => ({
        opacity: 1,
        config: { duration: 600 },
    }));

    const greetingCount = props.topic.greetingMessage.messages.length;

    const messageFadeIns = Array(greetingCount).fill(null).map(() => {
        return useSpring(() => ({
            opacity: 0,
            config: { duration: 600 },
        }));
    });

    const [videoFadeInStyle, videoFadeInApi] = useSpring(() => ({
        opacity: 0,
        config: { duration: 600 },
    }));

    const [continueButtonFadeInStyle, continueButtonFadeInApi] = useSpring(() => ({
        opacity: 0,
        config: { duration: 600 },
    }));

    useEffect(() => {
        if (state.currentMessageFadeIn > -1) {
            if (messageFadeIns.length === 0) {
                activateGreetingTimer();
            } else {
                messageFadeIns[state.currentMessageFadeIn][1].start({
                    opacity: 1,
                    onRest: () => {
                        setState({ ...state, currentMessageText: state.currentMessageFadeIn });
                    }
                });
            }
        }
    }, [state.currentMessageFadeIn]);

    const activateGreetingTimer = () => {
        if (props.topic.greetingMessage.videoUrl) {
            videoFadeInApi.start({
                opacity: 1,
                onRest: () => { videoFadeInWasComplete() }
            });
        } else {
            setTimeout(() => {
                fadeOut();
            }, props.topic.greetingMessage.duration * 1000);
        }
    };

    const videoRef = useRef<HTMLVideoElement>(null);
    const videoLoadedRef = useRef<boolean>(false);
    const videoFadeInCompleteRef = useRef<boolean>(false);
    const continueButtonRef = useRef<HTMLButtonElement>(null);
    const continueButtonVisibleRef = useRef<boolean>(false);
    //const playedAtLeastOnce = useRef<boolean>(false);

    //const eventsToListenFor = ['touchstart', 'touchmove', 'touchend', 'mousemove'];

    /*const addEventListeners = () => {
        for (const eventType of eventsToListenFor) {
            window.addEventListener(eventType, handleUserInteraction);
        }
    };*/

    /*const removeEventListeners = () => {
        for (const eventType of eventsToListenFor) {
            window.removeEventListener(eventType, handleUserInteraction);
        }
    };*/

    /*const handleUserInteraction = () => {
        console.log('handleUserInteraction');
        if (videoLoadedRef.current && videoFadeInCompleteRef.current) {
            if (videoRef.current && (videoRef.current.paused || videoRef.current.ended)) {
                //if (!playedAtLeastOnce.current) {
                console.log('Calling playVideo 1');
                playVideo();
                removeEventListeners();
                //}
            }
        }
    };*/

    /*useEffect(() => {
        addEventListeners();
        return () => { removeEventListeners(); };
    }, []);*/

    const fadeOut = () => {
        titlePanelApi.start({
            opacity: 0,
            onRest: props.onComplete
        });
    };

    const videoFadeInWasComplete = () => {
        videoFadeInCompleteRef.current = true;

        if (videoLoadedRef.current) {
            playVideo();
        }
    };

    const videoWasLoaded = () => {
        videoLoadedRef.current = true;

        if (videoFadeInCompleteRef.current) {
            playVideo();
        }
    };

    const playVideo = () => {
        if (videoRef.current) {
            //playedAtLeastOnce.current = true;
            //removeEventListeners();
            videoRef.current.play().catch((err) => {
                console.error("Error attempting to play:", err);
                if (videoRef.current)
                    videoRef.current.controls = true;
            });

            setTimeout(() => {
                continueButtonFadeInApi.start({
                    opacity: 1,
                    onRest: () => {
                        if(continueButtonRef.current)
                            continueButtonRef.current.style.cursor = 'pointer';
                        continueButtonVisibleRef.current = true;
                    }
                });
            }, 1000);
        }
    };

    const handleVideoEnded = () => {
        setTimeout(() => {
            fadeOut();
        }, props.topic.greetingMessage.duration * 1000);
    };

    const handleContinueButtonClicked = () => {
        if(continueButtonVisibleRef.current)
            fadeOut();
    };

    useEffect(() => {
        const url = props.topic.greetingMessage.videoUrl;

        if (url && videoRef.current) {

            videoRef.current.addEventListener('ended', handleVideoEnded);

            if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(url);
                hls.attachMedia(videoRef.current);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    videoWasLoaded();
                });

                hls.on(Hls.Events.ERROR, (event, data) => {
                    if (data.fatal) {
                        switch (data.type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                console.error("Fatal network error encountered, trying to recover");
                                hls.startLoad();
                                break;
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                console.error("Fatal media error encountered, trying to recover");
                                hls.recoverMediaError();
                                break;
                            default:
                                hls.destroy();
                                break;
                        }
                    }
                });
            } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                if (videoRef.current) {
                    videoRef.current.src = url;
                    videoRef.current.addEventListener('loadedmetadata', () => {
                        videoWasLoaded();
                    });
                }
            }
        }

        return () => {
            if (videoRef.current)
                videoRef.current.removeEventListener('ended', handleVideoEnded);
        };

    }, [props.topic.greetingMessage.videoUrl]);

    return (
        <animated.div style={titlePanelStyle}>
            {props.topic.greetingMessage.messages.map((message, i) => (
                <animated.div key={i} style={{ padding: '20px 20px 0 20px', ...messageFadeIns[i][0] }}>
                    <StyledContentPanel>
                        <TypingTextV2
                            active={state.currentMessageText === i}
                            delay={10}
                            onComplete={() => {
                                if (state.currentMessageFadeIn + 1 < greetingCount)
                                    setState({ ...state, currentMessageFadeIn: state.currentMessageFadeIn + 1 });
                                else
                                    activateGreetingTimer();
                            }}
                        >
                            <h1 key={i}>{message.text}</h1>

                        </TypingTextV2>
                    </StyledContentPanel>
                </animated.div>
            ))}
            {!props.topic.greetingMessage.videoUrl ? null :
                <>
                    <animated.div style={{ padding: '20px', ...videoFadeInStyle }}>
                        <div style={{
                            width: '100%',
                            marginBottom: '20px',
                            borderRadius: '20px',
                            overflow: 'hidden'
                        }}>
                            <video ref={videoRef} width={'100%'} playsInline muted />
                        </div>
                    </animated.div>
                    <animated.div style={{ padding: '20px', ...continueButtonFadeInStyle }}>
                        <StyledButton ref={continueButtonRef} style={{ cursor: 'auto' }} onClick={handleContinueButtonClicked}>
                            Продължи
                        </StyledButton>
                    </animated.div>
                </>
            }
        </animated.div>
    );
};

export default Component;