import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                "general_male": 'male',
                "general_female": 'female',
                "general_male_plural": 'men',
                "general_female_plural": 'women',
                "general_error_message": "Sorry, there was an error.",
                "general_rotate_device": "Rotate your device",

                "button_back": "Back",
                "button_confirm": 'Confirm',
                "button_continue": 'Continue',
                "label_you": "You",

                "image_upload_button_label": "Upload image",
                "image_upload_loading": "Uploading image",
                "image_upload_delete": "Delete image",

                "cookie_consent_title": "Allow cookies",
                "cookie_consent_text": "This tool is designed to make your opinions count. Therefore, we only use our own cookie to save your settings, voting history and for analytical purposes.",
                "cookie_consent_according": "According to our",
                "cookie_consent_allow": "Accept all cookies",

                "input_placeholder_name": 'Your name',
                "input_placeholder_gender": 'Your gender',
                "input_placeholder_birth_year": 'Your birth year',
                "input_placeholder_city": 'City',
                "input_placeholder_region": 'Region',
                "input_placeholder_country": 'Country',

                "home_message": "Hello, with us you see what Bulgaria says about:",

                "menu_main_title": "Menu",
                "menu_main_profile": "Profile",
                "menu_main_about_us": "About us",
                "menu_main_language": "Language",
                "menu_main_privacy_notice": "Privacy notice",

                "menu_profile_title": "Profile",
                "menu_profile_button_save": 'Save changes',
                "menu_profile_button_discard": 'Discard changes',
                "menu_profile_button_delete": 'Delete profile',
                "menu_profile_toast_success": "Profile saved successfully.",
                "menu_profile_ays_title": "Are you sure you want to delete your profile?",
                "menu_profile_ays_subtitle": "This action is irreversible!",
                "menu_profile_ays_button_yes": "Yes",
                "menu_profile_ays_button_no": "No",

                "menu_language_title": "Choose language",

                "slider_subtitle": "tell us by sliding the ball from 1 to 10",
                "slider_subtitle_vote_value": "You chose <b>{{ voteValue }} out of 10</b>",
                "slider_saying_to": "saying to",

                'qna_open_ended_disclaimer': 'By pressing this button you agree that what you have written will be made available to the organization operating this channel for feedback.',
            
                "end_screen_vote_again": "Give your opinion again",
                "end_screen_copy_link": "Copy link to the topic",
                "end_screen_copied_link" : "Copied!",
                "end_screen_share_topic" : "Share topic with friends",

                "results_majority_first_vote": "You are <b>the first</b> to answer this question!",
                "results_majority_strong_agreement": "Most people <b>agree</b> with you.",
                "results_majority_weak_agreement": "Most people <b>mostly agree</b> with you.",
                "results_majority_weak_disagreement": "Most people <b>mostly disagree</b> with you.",
                "results_majority_strong_disagreement": "Most people <b>disagree</b> with you.",

                "results_gender_strong_agreement": "Most <b>{{ genderStr }}</b> <b>agree</b> with you!",
                "results_gender_weak_agreement": "Most <b>{{ genderStr }}</b> <b>mostly agree</b> with you!",
                "results_gender_weak_disagreement": "Most <b>{{ genderStr }}</b> <b>mostly disagree</b> with you!",
                "results_gender_strong_disagreement": "Most <b>{{ genderStr }}</b> <b>disagree</b> with you!",
                "results_gender_first_vote_male": "You are <b>the first man</b> to answer this question!",
                "results_gender_first_vote_female": "You are <b>the first woman</b> to answer this question!",
                "results_gender_input_let_me_show_you": "Let me show you if people of your gender agree with you!",
                "results_gender_input_disclaimer": "Your gender will not be disclosed to anyone.",

                "results_age_strong_agreement": "Most people of your age <b>agree</b> with you.",
                "results_age_weak_agreement": "Most people of your age <b>mostly agree</b> with you.",
                "results_age_weak_disagreement": "Most people of your age <b>mostly disagree</b> with you.",
                "results_age_strong_disagreement": "Most people of your age <b>disagree</b> with you.",
                "results_age_first_vote": "You are <b>the first</b> of your age to answer this question!",
                "results_age_input_let_me_show_you": "Let me show you if people of your age agree with you!",
                "results_age_input_disclaimer": "Your age will not be disclosed to anyone.",

                "results_age_gender_first_vote_male": "You are <b>the first man</b> of your age to answer this question!",
                "results_age_gender_first_vote_female": "You are <b>the first woman</b> of your age to answer this question!",
                "results_age_gender_input_let_me_show_you": "Tell us your age and gender so that we can show you if people of your age and gender agree with you!",
                "results_age_gender_strong_agreement": "Most <b>{{ genderStr }}</b> of your age <b>agree</b> with you!",
                "results_age_gender_weak_agreement": "Most <b>{{ genderStr }}</b> of your age <b>mostly agree</b> with you!",
                "results_age_gender_weak_disagreement": "Most <b>{{ genderStr }}</b> of your age <b>mostly disagree</b> with you!",
                "results_age_gender_strong_disagreement": "Most <b>{{ genderStr }}</b> of your age <b>disagree</b> with you!",
            }
        },
        bg: {
            translation: {
                "general_male": 'мъж',
                "general_female": 'жена',
                "general_male_plural": 'мъже',
                "general_female_plural": 'жени',
                "general_error_message": "Съжаляваме, възникна грешка.",
                "general_rotate_device": "Завъртете устройството си",

                "button_back": "Назад",
                "button_confirm": 'Потвърди',
                "button_continue": 'Продължи',
                "label_you": "Вие",

                "image_upload_button_label": "Качи изображение",
                "image_upload_loading": "Качване на изображение",
                "image_upload_delete": "Изтрий изображение",
                
                "cookie_consent_title": "Разрешете бисквитки",
                "cookie_consent_text": "Този инструмент е създаден, за да прави мненията Ви значими. Затова използваме само наша собствена бисквитка, за да пазим настройките Ви, историята на гласуване и за аналитични цели.",
                "cookie_consent_according": "Съгласно нашата",
                "cookie_consent_allow": "Съгласен съм",
                
                "input_placeholder_name": 'Вашето име',
                "input_placeholder_gender": 'Вашият пол',
                "input_placeholder_birth_year": 'Годината Ви на раждане',
                "input_placeholder_city": 'Град',
                "input_placeholder_region": 'Район',
                "input_placeholder_country": 'Държава',

                "home_message": "Здравейте, с нас виждате какво казва България за:",

                "menu_main_title": "Меню",
                "menu_main_profile": "Профил",
                "menu_main_about_us": "За нас",
                "menu_main_language": "Език",
                "menu_main_privacy_notice": "Политика за поверителност",

                "menu_profile_title": "Профил",
                "menu_profile_button_save": 'Запази промените',
                "menu_profile_button_discard": 'Откажи промените',
                "menu_profile_button_delete": 'Изтрий профил',
                "menu_profile_toast_success": "Профилът e запазен успешно.",
                "menu_profile_ays_title": "Сигурни ли сте, че искате да изтриете Вашия профил?",
                "menu_profile_ays_subtitle": "Това действие е необратимо!",
                "menu_profile_ays_button_yes": "Да",
                "menu_profile_ays_button_no": "Не",

                "menu_language_title": "Изберете език",

                "slider_subtitle": "кажете с плъзгане на топчето от 1 до 10",
                "slider_subtitle_vote_value": "Вие избрахте <b>{{ voteValue }} от 10</b>",
                "slider_saying_to": "казвате на",
                
                'qna_open_ended_disclaimer': 'С натискането на този бутон се съгласявате написаното да бъде предоставено на организацията, оперираща този канал за обратна връзка.',

                "end_screen_vote_again": "Кажете мнението си пак",
                "end_screen_copy_link": "Копирай линк към темата",
                "end_screen_copied_link" : "Копирано!",
                "end_screen_share_topic" : "Сподели въпроса с приятели",

                "results_majority_first_vote": "Вие сте <b>първият</b>, който отговаря на този въпрос!",
                "results_majority_strong_agreement": "Повечето хора са <b>съгласни</b> с Вас.",
                "results_majority_weak_agreement": "Повечето хора са <b>по-скоро съгласни</b> с Вас.",
                "results_majority_weak_disagreement": "Повечето хора са <b>по-скоро несъгласни</b> с Вас.",
                "results_majority_strong_disagreement": "Повечето хора са <b>несъгласни</b> с Вас.",

                "results_gender_strong_agreement": "Повечето <b>{{ genderStr }}</b> са <b>съгласни</b> с Вас!",
                "results_gender_weak_agreement": "Повечето <b>{{ genderStr }}</b> са <b>по-скоро съгласни</b> с Вас!",
                "results_gender_weak_disagreement": "Повечето <b>{{ genderStr }}</b> са <b>по-скоро несъгласни</b> с Вас!",
                "results_gender_strong_disagreement": "Повечето <b>{{ genderStr }}</b> са <b>несъгласни</b> с Вас!",
                "results_gender_first_vote_male": "Вие сте <b>първият мъж,</b> който отговаря на този въпрос!",
                "results_gender_first_vote_female": "Вие сте <b>първата жена,</b> която отговаря на този въпрос!",
                "results_gender_input_let_me_show_you": "Нека Ви покажа дали хората от Вашия пол са съгласни с Вас!",
                "results_gender_input_disclaimer": "Вашият пол няма да бъде споделен с никого.",

                "results_age_strong_agreement": "Повечето хора на Вашата възраст са <b>съгласни</b> с Вас.",
                "results_age_weak_agreement": "Повечето хора на Вашата възраст са <b>по-скоро съгласни</b> с Вас.",
                "results_age_weak_disagreement": "Повечето хора на Вашата възраст са <b>по-скоро несъгласни</b> с Вас.",
                "results_age_strong_disagreement": "Повечето хора на Вашата възраст са <b>несъгласни</b> с Вас.",
                "results_age_first_vote": "Вие сте <b>първият</b> на Вашата възраст, който отговаря на този въпрос!",
                "results_age_input_let_me_show_you": "Нека Ви покажа дали хората на Вашата възраст са съгласни с Вас!",
                "results_age_input_disclaimer": "Вашата възраст няма да бъде споделена с никого.",

                "results_age_gender_strong_agreement": "Повечето <b>{{ genderStr }}</b> на Вашата възраст са <b>съгласни</b> с Вас!",
                "results_age_gender_weak_agreement": "Повечето <b>{{ genderStr }}</b> на Вашата възраст са <b>по-скоро съгласни</b> с Вас!",
                "results_age_gender_weak_disagreement": "Повечето <b>{{ genderStr }}</b> на Вашата възраст са <b>по-скоро несъгласни</b> с Вас!",
                "results_age_gender_strong_disagreement": "Повечето <b>{{ genderStr }}</b> на Вашата възраст са <b>несъгласни</b> с Вас!",
                "results_age_gender_first_vote_male": "Вие сте <b>първият мъж</b> на Вашата възраст, който отговаря на този въпрос!",
                "results_age_gender_first_vote_female": "Вие сте <b>първата жена</b> на Вашата възраст, която отговаря на този въпрос!",
                "results_age_gender_input_let_me_show_you": "Изберете възраст и пол, за да Ви покажем дали хората от Вашия пол и на Вашата възраст са съгласни с Вас!",
            }
        }
    },
    detection: {
        order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        caches: ['localStorage', 'cookie'],
    },
    fallbackLng: "bg",
    interpolation: {
        escapeValue: false
    }
});

export default i18n;