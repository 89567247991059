import React, { useContext, useRef, useState } from 'react';

import axios from 'axios';
import { AppContext } from '../../../App';
import { DeleteImageFunction, SetImageFunction } from './OpenStructure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera';
import StyledButton from '../../../components/StyledButton';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { SpinnerCircularFixed } from 'spinners-react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ApproveImagePanel = styled.div`
    background-color: #00000055;
    padding: 20px;
    color: white;
    border-radius: 20px;
    display: inline-block;
    font-size: 24px;
    margin-bottom: 20px;
    width: 100%;

    img {
        width: 100%;
        border-radius: 20px;
        margin-bottom: 20px;
    }
`;

interface UploadPhotoProps {
    number: number;
    value: { text: string; image: string | null };
    setImage: SetImageFunction;
    deleteImage: DeleteImageFunction;
}

interface UploadPhotoState {
    imageBlobURL: string | null;
    processingImage: boolean;
}

export default (props: UploadPhotoProps) => {
    const { apiUrl, topic } = useContext(AppContext);

    const { t } = useTranslation();

    const [state, setState] = useState<UploadPhotoState>({
        imageBlobURL: null,
        processingImage: false,
    });

    const imageInputRef = useRef<HTMLInputElement>(null);

    const onImageSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const img = new Image();
        const imgUrl = URL.createObjectURL(file);
        img.src = imgUrl;

        setState({ ...state, processingImage: true });

        img.onload = async () => {

            const MAX_PIXELS = 1600 * 900;
            const pixels = img.width * img.height;

            if (pixels <= MAX_PIXELS) {

                await uploadImage(file);
                setState({ ...state, imageBlobURL: imgUrl, processingImage: false });

            } else {

                const targetRatio = img.width / img.height;
                const targetHeight = Math.floor(Math.sqrt(MAX_PIXELS / targetRatio));
                const targetWidth = Math.floor(targetHeight * targetRatio);
                const result: Blob | null = await resizeImage(img, targetWidth, targetHeight);

                if (!result) return;

                await uploadImage(result);
                setState({ ...state, imageBlobURL: URL.createObjectURL(result), processingImage: false });

            }
        };
    };

    const resizeImage = async (image: HTMLImageElement, targetWidth: number, targetHeight: number): Promise<Blob | null> => {
        const canvas = document.createElement('canvas');
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        const ctx = canvas.getContext('2d');
        if (!ctx) throw new Error('Could not get context');
        ctx.drawImage(image, 0, 0);

        ctx.drawImage(image, 0, 0, targetWidth, targetHeight);

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) reject('Could not get blob');
                resolve(blob);
            }, 'image/jpeg', 0.9);
        });
    };

    const uploadImage = async (imgBlob: Blob) => {
        const formData = new FormData();

        if (!imgBlob) return;

        formData.append('image', imgBlob, 'image');

        const res = await axios.post(`${apiUrl}/qna/${topic?.id}/img_upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });

        props.setImage(props.number, res.data.url);
    };

    const deleteImage = async () => {
        setState({ ...state, imageBlobURL: null });
        props.deleteImage(props.number);
    };

    const handleButtonClick = () => {
        if (imageInputRef.current)
            imageInputRef.current.click();
    };

    return (
        <>
            <input
                ref={imageInputRef}
                type="file"
                id="myfile"
                name="myfile"
                onChange={onImageSelect}
                style={{ display: 'none' }}
                capture="user"
            />
            {state.imageBlobURL === null && state.processingImage === false ?
                <StyledButton onClick={handleButtonClick}>
                    <FontAwesomeIcon icon={faCamera} /> {t('image_upload_button_label')}
                </StyledButton> :
                <ApproveImagePanel>
                    {state.processingImage
                        ? <div style={{
                            width: '100%',
                            borderRadius: '20px',
                            marginBottom: '20px',
                            textAlign: 'center',
                            height: '160px',
                            paddingTop: '45px',
                            backgroundColor: '#0006'
                        }}>
                            <SpinnerCircularFixed
                                speed={400}
                                color={'#666'}
                                secondaryColor={'#ddd'}
                                thickness={100}
                                size={50}
                            />
                            <p style={{
                                color: 'white',
                                marginTop: 0,
                                fontSize: '15px'
                            }}>{t('image_upload_loading')}</p>
                        </div>
                        : <img src={state.imageBlobURL ?? ''} />
                    }
                    <StyledButton onClick={deleteImage} style={{ marginBottom: 0 }}>
                        <FontAwesomeIcon icon={faTrash} /> {t('image_upload_delete')}
                    </StyledButton>
                </ApproveImagePanel>
            }
        </>
    );
};